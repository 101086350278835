<template>
  <div>
     
        <div class="col-md-4 col-sm-4 col-xs-6 p-image">
            <router-link v-if="$i18n.locale == 'en'" :to="'/'+refs+'/'+item.slug">
                <img 
                    v-lazy="(item.image) ? item.image.url : ''" 
                    :src="(item.image) ? item.image.url : ''" 
                    :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt : ''" 
                    :title="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title : ''" 
                    class="wh80"/>
            </router-link>
            <router-link v-if="$i18n.locale == 'ar'" :to="'/'+refs+'/'+item.slug+'lang=ar'">
                <img
                        v-lazy="(item.image) ? item.image.url : ''"
                        :src="(item.image) ? item.image.url : ''"
                        :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt : ''"
                        :title="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title : ''"
                        class="wh80"/>
            </router-link>
        </div>
        
        <div class="col-md-8 col-sm-8 col-xs-6">
            <div class="feature-p-text">
                <h4 class="bottom15">
                    <router-link v-if="$i18n.locale == 'en'"
                        :to="'/'+refs+'/'+item.slug"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        {{ ($i18n.locale == 'ar') ? item.title_ar : item.title_en }}
                    </router-link>
                    <router-link v-if="$i18n.locale == 'ar'"
                            :to="'/'+refs+'/'+item.slug+'lang=ar'"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        {{ ($i18n.locale == 'ar') ? item.title_ar : item.title_en }}
                    </router-link>
                </h4>
                <a href="javascript:;" v-if="item.price">
                    {{ item.price }}
                </a>
                <a href="javascript:;" v-if="item.category">
                    {{ ($i18n.locale == 'ar') ? item.category.title_ar : item.category.title_en }}
                </a>
            </div>
        </div>

  </div>
</template>

<script>
export default {
    name: 'FeautedCard',
    props: ['item', 'refs'],
    data(){
        return {
            //
        }
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
